<template>
	<div id="yxActivity-container" class="yxDetail-container">
		<div>
			<navigation></navigation>
			<div class="detail-desc-box">
				<div class="detail-desc-title">
					{{ courseDetail.title }}
				</div>
				<div class="detail-desc">
					{{ courseDetail.recommend }}
				</div>
			</div>
			<div class="detail-info">
				<div class="detail-info-item">主讲人：{{ courseDetail.speaker }}</div>
				<div class="detail-info-item">参加对象：{{ courseDetail.participant }}</div>
				<div class="detail-info-item">最多参与人数：{{ courseDetail.maxSignUp?courseDetail.maxSignUp:999 }}</div>
				<div class="detail-info-item">活动时间：{{ courseDetail.activityDate +timeFrameText }}</div>
				<div class="detail-info-item" v-if="courseDetail.activityType == '2'||courseDetail.activityType == '0'">活动地点(线下)：{{ courseDetail.locationRoom }}</div>
				<div class="detail-info-item" v-else-if="courseDetail.activityType == '1'||courseDetail.activityType == '0'">活动地点(线上)：{{ courseDetail.address }}
				</div>
				<div class="detail-info-item">
					报名截止时间：{{ courseDetail.deadline?courseDetail.deadline:courseDetail.activityDate + ' ' + courseDetail.endTime }}
				</div>
				<div style="width: 100%;display: flex;justify-content: space-between;align-items: center">
					<div>已报名人数：<span
							style="color: #409EFF">{{ courseDetail.concurSignUp?courseDetail.concurSignUp:0 }}</span>
					</div>
					<div class="info-btn-box">
						<div v-if="pjBtnStatus == '1'">
							<div class="rota-box" v-if="courseDetail.evaluationType == '0'">
								<div @click="rotaHandleNull" class="sign-btn-disable">评价未开启</div>
							</div>
							<div class="rota-box" v-else>
								<div @click="rotaHandle"
									v-if="activityStatus == 2 && !courseDetail.activityEvaluationId"
									class="sign-btn">点击评价</div>
								<!-- <div v-if="activityStatus == 2 &&activityStatus != 0 && courseDetail.activityEvaluationId"
									class="rota-num">活动评分：{{ courseDetail.scoreResult }}</div> -->
								<div v-if="activityStatus == 2 && courseDetail.activityEvaluationId"
									class="sign-btn-disable">已评价</div>
							</div>
						</div>
						<div @click="signUpHandel" v-if="signBtnStatus == '0'" class="sign-btn">我要报名</div>
						<div @click="signUpCancel" v-else-if="signBtnStatus == '1'" class="sign-btn">取消报名</div>
						<div v-else-if="signBtnStatus == '2'" class="sign-btn-disable">报名已结束</div>
						<div v-else-if="signBtnStatus == '3'" class="sign-btn-disable">已报满</div>
						<div class="sign-btn" style="margin-left:20px" @click="returnList">返回研修活动</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog custom-class="dia-box" title="提示" :visible.sync="dialogVisible" :close-on-press-escape="false"
			:close-on-click-modal="false" width="500px">
			<div class="dia-body">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
					<el-form-item v-if="courseDetail.evaluationType == '1'||courseDetail.evaluationType == '3'"
						class="el-form-item-rota" label="星级评分" prop="score">
						<div class="rota-input">
							<el-rate v-model="ruleForm.score"></el-rate>
						</div>
					</el-form-item>
					<el-form-item v-if="courseDetail.evaluationType == '2'||courseDetail.evaluationType == '3'"
						label="评语" prop="comment">
						<el-input type="textarea" resize="none" rows="5" v-model="ruleForm.comment"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="diaCancel">取 消</el-button>
				<el-button type="primary" @click="diaSumit('ruleForm')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		activityInfoApi,
		activityEvaluationApi,
		activitySignUpAddApi,
		activitySignUpDelApi
	} from '@/api/newApi';
	import dateUtil from '@/util/dateUtil'
	import cookieUtil from "@/util/cookieUtil"
	import navigation from '@/components/navigation'
	import {
		Message
	} from 'element-ui'
	export default {
		name: "detail",
		components: {
			navigation
		},
		data() {
			return {
				timeFrameText: '全天',
				dialogVisible: false,
				courseDetail: {
					evaluationType: '0',
				},
				signBtnStatus: '2',
				pjBtnStatus:'0',
				activityStatus: '0',
				ruleForm: {
					score: 0,
					comment: '',
				},
				isLogin:false,
				rules: {
					score: [{
						required: true,
						message: '请选择评分',
						trigger: 'change'
					}],
					comment: [{
						required: true,
						message: '请填写评语',
						trigger: 'blur'
					}]
				}
			}
		},
		mounted() {
			let JSESSIONID = cookieUtil.getCookie("JSESSIONID") || '';
			(JSESSIONID || this.$route.query.JSESSIONID) && this.$api.home.getUserInfo({}, false).then(res => {
				if (res.code == 200) {
					this.isLogin = true
				}
			})
			this.getCourseDetail(this.$route.params.id)
		},
		methods: {
			//返回列表
			returnList(){
				this.$router.go(-1)
			},
			signUpHandel() {
				if(this.isLogin){
					this.$confirm('是否确认参加报名?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						return activitySignUpAddApi({
							activityId: this.courseDetail.id
						})
					}).then(res => {
						console.log(res);
						this.getCourseDetail(this.$route.params.id)
					}).catch(() => {});
				}else{
					this.$confirm('请先登录账号！', '提示', {
						confirmButtonText: '立即登录',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$toLogin('https://www.tzjsyxw.cn/')
					}).catch(() => {});
				}
			},
			signUpCancel() {
				this.$confirm('是否确认取消报名?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					return activitySignUpDelApi({
						id: this.courseDetail.activitySignUpId
					})
				}).then(() => {
					this.getCourseDetail(this.$route.params.id)
				}).catch(() => {});
			},
			reset() {
				this.ruleForm = {
					score: 0,
					comment: '',
				};
				this.resetForm('ruleForm')
			},
			rotaHandle() {
				if(this.isLogin){
					this.reset();
					this.dialogVisible = true;
				}else{
					this.$confirm('请先登录账号！', '提示', {
						confirmButtonText: '立即登录',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$toLogin('https://www.tzjsyxw.cn/')
					}).catch(() => {});
				}
				
			},
			rotaHandleNull() {
				Message({
					message: '当前活动暂时没有开启评价',
					type: 'warning'
				})
			},
			diaCancel() {
				this.reset();
				this.dialogVisible = false;
			},
			diaSumit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// this.dialogVisible = false;
						let json = {
							activityId: this.courseDetail.id,
							score: this.ruleForm.score * 20,
							comment: this.ruleForm.comment,
						}
						activityEvaluationApi(json).then(res => {
							Message({
								message: '评价成功',
								type: 'success'
							})
							this.getCourseDetail(this.$route.params.id)
							this.dialogVisible = false;
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			getCourseDetail(id) {
				activityInfoApi({
					activityId: id,
				}).then(res => {
					this.courseDetail = res.result;
					if(res.result.location&&res.result.location.indexOf('通州区教师研修中心')>-1&&res.result.classRoom){
						this.courseDetail.locationRoom = res.result.location+res.result.classRoom;
					}else{
						this.courseDetail.locationRoom = res.result.location;
					}
					// console.log(this.courseDetail)
					// this.courseDetail.coverUrl = '../../assets/defaultImg.png'
					this.timeFrameText = res.result.timeFrame != '0' ? res.result.timeFrame != '1' ? '下午' : '上午' :
						'全天'
					let activityDateNum = 0;
					if (res.result.deadline) {
						activityDateNum = Number(dateUtil.getUnixTime(res.result.deadline));
					} else {
						activityDateNum = Number(dateUtil.getUnixTime(res.result.activityDate +
							' ' + res.result.endTime));
					}
					let nowTime = dateUtil.formatDateThis(new Date());
					let nowDateNum = Number(dateUtil.getUnixTime(nowTime))
					//signBtnStatus  按钮状态0未报名，1已报名，2报名结束，3已满
					let startNum = Number(dateUtil.getUnixTime(res.result.activityDate +
						' ' + res.result.startTime));
					let endNum = Number(dateUtil.getUnixTime(res.result.activityDate +
						' ' + res.result.endTime));
					if (endNum < nowDateNum) {
						this.activityStatus = '2'
					} else if (startNum > nowDateNum) {
						this.activityStatus = '0'
					} else {
						this.activityStatus = '1'
					}
					if(res.result.activitySignUpId){
						this.pjBtnStatus = '1';
					}else{
						this.pjBtnStatus = '0';
					}
					if (activityDateNum < nowDateNum) {
						this.signBtnStatus = '2';
					} else {
						if (res.result.activitySignUpId) {
							this.signBtnStatus = '1';
						} else {
							if (res.result.concurSignUp && res.result.concurSignUp == res.result.maxSignUp) {
								this.signBtnStatus = '3';
							} else {
								this.signBtnStatus = '0';
							}
						}
					}
				})
			},
			downloadAppend(appendixUrl) {
				window.open(appendixUrl, '_blank')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.yxDetail-container {
		width: 100%;
		max-width: 1200px;
		margin: auto;
		min-height: calc(100vh - 449px);

		.detail-desc-box {
			padding: 20px;
			border-bottom: 1px solid #eee;
			background-color: #fff;
		}

		.detail-desc-title {
			width: 100%;
			text-align: center;
			font-size: 22px;
			font-weight: bolder;
			padding: 10px 0;
		}

		.detail-desc {
			text-indent: 2em;
			line-height: 2;
			font-size: 18px;
			text-align: left;
		}

		.detail-info {
			padding: 20px;
			background-color: #fff;
			text-align: left;
		}

		.detail-info-item {
			padding: 10px 0;
			font-size: 16px;
		}

		.download-info-item {
			cursor: pointer;
		}

		.download-info-item:hover {
			color: #409EFF;
		}

		.sign-btn {
			width: 174px;
			height: 56px;
			line-height: 56px;
			text-align: center;
			color: #fff;
			background-color: #409EFF;
			border-radius: 56px;
			cursor: pointer;
		}

		.sign-btn-disable {
			width: 174px;
			height: 56px;
			line-height: 56px;
			text-align: center;
			color: #fff;
			background-color: #ccc;
			border-radius: 56px;
		}

		.sign-btn:hover {
			background-color: #3CD9FF;
		}
	}

	.info-btn-box {
		display: flex;

		.rota-box {
			display: flex;
			align-items: center;
			margin-right: 15px;
		}
	}
</style>
<style lang="scss">
	.dia-box {
		.el-dialog__body {
			padding: 15px 20px !important;

			.el-form-item:last-child {
				margin-bottom: 0px;
			}
		}
	}

	.el-form-item-rota {
		.el-form-item__content {
			display: flex;
			line-height: 40px;
			height: 40px;
			align-items: center;
		}
	}
</style>