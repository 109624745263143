<template>
	<div class="filter-container">
		<div class="filter-row">
			<div class="row-title">
				学科：
			</div>
			<div class="row-filter-all" :class="subjectId === '' ? 'row-filter-item-active': ''"
				@click="filterClick('subjectId', '')">
				全部
			</div>
			<div class="row-filter-item-list">
				<div class="row-filter-item" v-for="(item, index) in subjectList" :key="item.value"
					:class="subjectId == item.value ? 'filter-item-active': ''" @click="filterClick('subjectId', item.value)">
					{{ item.label }}</div>
			</div>
		</div>
		<!--<div class="filter-row">-->
		<!--<div class="row-title">学段：</div>-->
		<!--<div class="row-filter-all">全部</div>-->
		<!--<div class="row-filter-item-list">-->
		<!--<div class="row-filter-item">学前阶段</div>-->
		<!--<div class="row-filter-item">小学</div>-->
		<!--<div class="row-filter-item">初中</div>-->
		<!--<div class="row-filter-item">高中</div>-->
		<!--</div>-->
		<!--</div>-->
		<div class="filter-row">
			<div class="row-title">年级：</div>
			<div class="row-filter-all" :class="gradeId === ''&&sectionId === '' ? 'row-filter-item-active': ''"
				@click="filterClick('all', '')">全部</div>
			<div class="section-grade-box-list">
				<div class="section-grade-box" v-for="item in tree" :key="item.id">
					<div class="row-filter-item-list section-box">
						<div class="row-filter-item" :class="sectionId == item.id ? 'row-filter-item-active': ''"
							@click="filterClick('sectionId', item.id)">{{item.name}}</div>
					</div>
					<div class="row-filter-item-list grade-box">
						<div class="row-filter-item" v-for="itemChild in item.children" :class="gradeId === itemChild.id ? 'row-filter-item-active': ''"
							@click="filterClick('gradeId', itemChild.id)">{{itemChild.name}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		subjectOptionApi,
		gradeTreeApi
	} from '@/api/newApi';
	export default {
		name: "activeFilter",
		data() {
			return {
				subjectList: [],
				tree:[],
				subjectId: '',
				sectionId:'',
				gradeId: '',
			}
		},
		mounted() {
			this.getSubjectOption().then(res => {
				this.subjectList = res.result;
			})
			this.gettreeOption().then(res => {
				this.tree = res.result;
			})
		},
		methods: {
			//获取学科
			getSubjectOption() {
				return new Promise((resolve, reject) => {
					subjectOptionApi().then(res => {
						resolve(res);
					}).catch(err => {
						reject(err);
					})
				})
			},
			//获取学段年级树
			gettreeOption() {
				return new Promise((resolve, reject) => {
					gradeTreeApi().then(res => {
						resolve(res);
					}).catch(err => {
						reject(err);
					})
				})
			},
			filterClick(key, value) {
				if(key == 'subjectId'){
					this.subjectId = value
				}else if(key == 'all'){
					this.sectionId = ''
					this.gradeId = ''
				}else if(key == 'sectionId'){
					this.sectionId = value;
					this.gradeId = ''
				}else if(key == 'gradeId'){
					this.sectionId = '';
					this.gradeId = value
				}
				this.$emit('filterClick', {
					subjectId: this.subjectId === '' ? '' : this.subjectId,
					sectionId: this.sectionId === '' ? '' : this.sectionId,
					gradeId: this.gradeId === '' ? '' : this.gradeId
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.filter-container {
		width: 100%;
		background-color: #fff;

		.filter-row {
			display: flex;
			border-bottom: 1px dotted #eee;
			padding: 10px 0;
			width:100%;
			.section-grade-box-list{
				flex: 1;
			}
			.grade-box{
				flex: 1 !important;
			}
		}

		.row-title {
			width: 100px;
			color: #1E1E1E;
			text-align: right;
			font-weight: bolder;
			padding: 5px 0;
		}

		.row-filter-all {
			width: 100px;
			text-align: center;
			padding: 5px 0;
			cursor: pointer;
		}

		.row-filter-item-active {
			color: #409EFF;
		}

		.row-filter-all:hover {
			color: #409EFF;
		}

		.row-filter-item-list {
			display: flex;
			flex-wrap: wrap;
			flex: 1;
			color: #1E1E1E;

			.row-filter-item {
				width: 120px;
				padding: 5px 0;
				text-align: center;
				cursor: pointer;
			}

			.row-filter-item-active {
				color: #409EFF;
			}

			.filter-item-active {
				color: #409EFF;
			}

			.row-filter-item:hover {
				color: #409EFF;
			}
		}
	}
	.section-grade-box{
		display: flex;
		justify-content: flex-start;
		.section-box,.grade-box{
			flex: none;
		}
	}
</style>