<!--研修活动-->
<template>
	<div class="dynamic-container" @click="toDetail(item)">
		<div class="image-box">
			<!-- <img :src="item.coverUrl" alt=""> -->
			<el-image :src="require('@/assets/defaultImg.png')" fit="cover">
				<div slot="error" class="image-slot">
					<i class="el-icon-picture"></i>
					<p>暂无封面</p>
				</div>
			</el-image>
		</div>
		<div class="desc-box">
			<div class="desc-title">{{ item.title }}</div>
			<div class="desc-subtitle">主讲人：{{ item.speaker }}</div>
			<div class="desc-subtitle">参加对象：{{ item.participant }}</div>
			<div class="desc-subtitle">
				活动时间：{{ item.activityDate }}{{item.timeFrameText}}</div>
			<div class="desc-subtitle">报名截止时间：{{ item.deadline?item.deadline:item.activityDate + ' ' + item.endTime }}
			</div>
			<div class="desc-subtitle-box">
				<div class="desc-subtitle" v-if="item.section">学段：{{ item.section }}</div>
				<div class="desc-subtitle" v-if="item.grade">年级：{{ item.grade }}</div>
				<div class="desc-subtitle" v-if="item.subject">学科：{{ item.subject }}</div>
			</div>

		</div>
		<div class="right-box">
			<slot name="rightBox"></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "activityItem",
		props: {
			item: []
		},
		mounted() {},
		methods: {
			toDetail(item) {
				this.$router.push({
					name: 'yxActivityDetail',
					params: {
						id: item.id
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.dynamic-container {
		padding: 24px 0;
		border-bottom: 1px solid #ddd;
		display: flex;
		cursor: pointer;

		

		.desc-box {
			flex: 1;
			height: 148px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			div {
				text-align: left;
			}

			.desc-title {
				font-size: 18px;
				font-weight: 500;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.desc-subtitle {
				font-size: 15px;
				color: #666;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			.desc-time {
				font-size: 14px;
				color: #999;
			}
		}
	}

	.desc-subtitle-box {
		display: flex;

		.desc-subtitle {
			margin-right: 20px;
		}
	}
</style>
<style lang="scss">
	.dynamic-container{
		.image-box {
			width: 210px;
			height: 148px;
			overflow: hidden;
			border: 1px solid #ddd;
			padding: 10px;
			margin-right: 20px;
			.el-image {
				width: 100%;
				height: 100%;
				.image-slot{
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					i{
						color:#ccc;
						font-size: 50px;
					}
					p{
						color:#ccc;
						margin:10px 0 0;
					}
				}
			}
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	
</style>