<template>
	<div class="yx-activity-container">
		<div class="content-div">
			<navigation></navigation>
			<el-breadcrumb style="background-color: #fff;padding: 30px 0 30px 30px"
				separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><span style="color: #767676;cursor: pointer;"
						@click="$router.push({name: 'home'})">首页</span></el-breadcrumb-item>
				<el-breadcrumb-item><span style="color: #00BCEA;">研修活动</span></el-breadcrumb-item>
			</el-breadcrumb>
			<activityFilter @filterClick="filterClick"></activityFilter>
			<div class="sort-box">
				<div style="display: flex">
					<div @click="sortClick('activity_date')" :class="queryParams.sortField === 'activity_date' ? 'sort-item-active': 'sort-item'">时间
					</div>
					<div @click="sortClick('concur_sign_up')" :class="queryParams.sortField === 'concur_sign_up' ? 'sort-item-active': 'sort-item'">人数
					</div>
				</div>
				<div class="search-box">
					<el-input class="search-input" placeholder="输入活动名称或标签" v-model="queryParams.keyword"></el-input>
					<div style="height: 10px;background-color: #ddd;width: 2px"></div>
					<img @click="searchByCourseTitle" style="width: 20px;height: 20px;cursor: pointer"
						src="../../assets/search-active.png" alt="">
				</div>
			</div>
			<div v-if="courseList.length > 0" class="activity-list">
				<dynamicItem v-for="(item, index) in courseList" :item="item" :key="index">
					<div class="activity-item-rightBox" slot="rightBox">
						<div v-if="item.signBtnStatus == '0'" class="activity-item-signStatus">报名</div>
						<div v-else-if="item.signBtnStatus == '1'" class="activity-item-signStatus">已报名</div>
						<div v-else-if="item.signBtnStatus == '2'" class="activity-item-signStatus-dis">已结束</div>
						<div v-else-if="item.signBtnStatus == '3'" class="activity-item-signStatus-dis">已满</div>
						<div class="activity-item-signNum">报名人数：{{ item.concurSignUp }}</div>
					</div>
				</dynamicItem>
			</div>
			<div v-else class="activity-list">
				暂无数据～
			</div>
			<div style="width: 100%; text-align: center; padding-bottom: 15px;background-color: #fff">
				<pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
					:limit.sync="queryParams.pageSize" @pagination="getList" />
			</div>
		</div>
	</div>
</template>

<script>
	import navigation from '@/components/navigation'
	import activityFilter from './activityFilter'
	import dynamicItem from '@/components/activityItem'
	import dateUtil from '@/util/dateUtil'
	import {
		activityListAnonApi,
	} from '@/api/newApi';
	export default {
		name: "index",
		components: {
			navigation,
			activityFilter,
			dynamicItem
		},
		data() {
			return {
				subject: '',
				grade: '',
				courseList: [],
				page: {
					pageSize: 20,
					pageNum: 1,
					total: 0
				},
				queryParams:{
					pageNum: 1,
					pageSize: 20,
					subjectId: undefined,
					sectionId: undefined,
					gradeId: undefined,
					sortField:'activity_date',
					sortOrder:'DESC',
					keyword: undefined,
					activityDate: undefined
				},
				total:0,
				sortType: '',
				courseTitle: ''
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			//
			searchByCourseTitle() {
				this.sortType = ''
				this.getList()
			},
			sortClick(sortType) {
				this.queryParams.sortField = sortType;
				this.getList()
			},
			filterClick(params) {
				let {
					subjectId,
					sectionId,
					gradeId
				} = params
				this.queryParams.subjectId = subjectId
				this.queryParams.sectionId = sectionId
				this.queryParams.gradeId = gradeId
				this.getList()
			},
			pageChange(pageNum) {
				this.page.pageNum = pageNum
				this.getList()
			},
			getList() {
				activityListAnonApi({
					keyword: this.queryParams.keyword,
					subjectId: this.queryParams.subjectId,
					sectionId: this.queryParams.sectionId,
					gradeId: this.queryParams.gradeId,
					sortField:this.queryParams.sortField,
					activityDate: this.queryParams.activityDate,
					offset: (parseInt(this.queryParams.pageNum) - 1) * this.queryParams.pageSize,
					limit: this.queryParams.pageSize
				}).then(res => {
					const {
						code,
						result
					} = res;
					if (code == 200) {
						this.courseList = result.rows.map(item=>{
							item.coverUrl = '../../assets/defaultImg.png'
							item.timeFrameText = item.timeFrame != '0'?item.timeFrame != '1'?'下午':'上午':'全天'
							item.subject = item.subject?item.subject:'';
							item.grade = item.grade?item.grade:'';
							item.section = item.section?item.section:'';
							item.concurSignUp = item.concurSignUp?item.concurSignUp:0;
							let activityDateNum = 0;
							if (item.deadline) {
								activityDateNum = Number(dateUtil.getUnixTime(item.deadline));
							} else {
								activityDateNum = Number(dateUtil.getUnixTime(item.activityDate +
									' ' + item.endTime));
							}
							let nowTime = dateUtil.formatDateThis(new Date());
							let nowDateNum = Number(dateUtil.getUnixTime(nowTime))
							//signBtnStatus  按钮状态0未报名，1已报名，2报名结束，3已满
							if (activityDateNum < nowDateNum) {
								item.signBtnStatus = '2';
							} else {
								if (item.activitySignUpId) {
									item.signBtnStatus = '1';
								} else {
									if(item.concurSignUp&&item.concurSignUp == item.maxSignUp){
										item.signBtnStatus = '3';
									}else{
										item.signBtnStatus = '0';
									}
								}
							}
							return item;
						})
						this.total = result.total || 0
					}
				})
			},
			itemClick(item) {
				console.log(123, item)
			}
		}
	}
</script>

<style lang="scss">
	.content-div {
		width: 100%;
		max-width: 1200px;
		margin: auto;

		.search-box {
			width: 334px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-right: 26px;
			background-color: #F5F5F5;
			height: 36px;
			border-radius: 36px;
			padding: 0 10px;

			.search-input {
				width: 260px;
				height: 30px;

				.el-input__inner {
					border: none;
					height: 30px;
					line-height: 30px;
					background: transparent;
				}
			}
		}

		.sort-box {
			width: 100%;
			border-top: 3px solid #00BCEA;
			display: flex;
			justify-content: space-between;
			background-color: #fff;
			align-items: center;

			.sort-item {
				width: 110px;
				height: 50px;
				line-height: 50px;
				text-align: center;
				color: #4A4A4A;
				font-size: 18px;
				cursor: pointer;
			}

			.sort-item-active {
				width: 110px;
				height: 50px;
				line-height: 50px;
				text-align: center;
				color: #fff;
				background-color: #00BCEA;
				font-size: 18px;
				cursor: pointer;
			}
		}

		.activity-list {
			min-height: 600px;
			padding: 20px 20px 50px;
			background-color: #fff;

			.activity-item-rightBox {
				width: 180px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;

				.activity-item-signStatus {
					width: 120px;
					height: 30px;
					line-height: 30px;
					border-radius: 30px;
					background-color: #3CDC9A;
					color: #fff;
					cursor: pointer;
				}

				.activity-item-signStatus-dis {
					width: 120px;
					height: 30px;
					line-height: 30px;
					border-radius: 30px;
					background-color: #ccc;
					color: #fff;
				}

				.activity-item-signStatus:hover {
					background-color: #25E092;
				}

				.activity-item-signNum {
					color: #409EFF;
				}
			}
		}
	}

	.yx-activity-container {
		margin-bottom: 30px;
	}
</style>